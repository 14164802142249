import { Box } from '~/components/Primitives/Box';
import { styled } from '~/stitches.config';

export const StyledTable = styled('div', {});

export const StyledTableRow = styled('div', {
  backgroundColor: 'transparent',
  minWidth: '100%',
  height: 40,
  borderBottom: '1px solid $colors$gray100',

  '&:hover': {
    backgroundColor: '$gray100',
  },
});

export const StyledTableFooter = styled('div', {
  [`${StyledTableRow}`]: {
    backgroundColor: '$gray200',
    borderBottom: 0,
    borderRadius: 4,

    '&:hover': {
      backgroundColor: '$gray200',
    },
  },
});

export const StyledTableHeader = styled('div', {
  [`${StyledTableRow}`]: {
    backgroundColor: '$gray200',
    borderBottom: 0,
    borderRadius: 4,

    '&:hover': {
      backgroundColor: '$gray200',
    },
  },
});

export const StyledTableHeaderCell = styled('div', {
  flexShrink: 0,
  display: 'flex !important',
  alignItems: 'center',
  px: 12,
  color: '$gray800',
  fontFamily: '$inter',
  fontSize: 12,
  fontWeight: '$bold',
  letterSpacing: 0.8,
  lineHeight: '18px',
  textAlign: 'left',
  textTransform: 'uppercase',

  '&:first-of-type': {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },

  '&:last-of-type': {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
});

export const StyledTableCell = styled('div', {
  display: 'flex !important',
  alignItems: 'center',
  px: 12,
  color: '$gray800',
  fontFamily: '$inter',
  fontSize: 14,
  letterSpacing: 0,
  fontWeight: '$regular',
  lineHeight: '22px',
});

export const StyledTableBody = styled('div', {});
