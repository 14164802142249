import { createLocalStorageStateHook } from 'use-local-storage-state';

export const INITIAL_VALUES = {
  alternativeRowColor: false,
  highlightEditableFields: false,
  splitEvery10Rows: false,
};

export const useTableSettings = createLocalStorageStateHook<typeof INITIAL_VALUES>(
  'calculator/table-settings',
  INITIAL_VALUES,
);
