import Link from 'next/link';
import React from 'react';
import { analytics } from '~/analytics/analytics';

import { Box } from '~/components/Primitives/Box';
import { Button } from '~/components/Primitives/Button';
import { Text } from '~/components/Primitives/Text';

export const Toolbar = ({ currentStep, nextStep, previousStep }) => {
  return (
    <Box
      css={{
        position: 'fixed',
        bottom: 16,
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 560,
        height: 56,
        backgroundColor: '$background',
        border: '1px solid $colors$gray200',
        borderRadius: 4,
        boxShadow: '0 0 8px $colors$gray200',
        p: 12,
        pl: 24,
        transform: 'translateX(-50%)',
      }}
    >
      <Text css={{ mr: 24 }} size='body-12'>
        You are currently on&nbsp;
        <Box as='strong' css={{ fontWeight: 'semibold' }}>
          {currentStep}
        </Box>
      </Text>
      <Box css={{ display: 'flex' }}>
        {previousStep && (
          <Link href={previousStep} passHref>
            <Button
              appearance='secondary'
              as='a'
              onClick={() => analytics.track('Clicked button', { parentComponent: 'toolbar', label: 'Previous' })}
            >
              Previous
            </Button>
          </Link>
        )}
        {nextStep && (
          <Link href={nextStep} passHref>
            <Button
              as='a'
              css={{ ml: 8 }}
              onClick={() => analytics.track('Clicked button', { parentComponent: 'toolbar', label: 'Next step' })}
            >
              Next step
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
};
