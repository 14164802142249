import React from 'react';
import { useTable, useBlockLayout } from 'react-table';
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableFooter,
  StyledTableHeader,
  StyledTableHeaderCell,
  StyledTableRow,
} from '~/components/Primitives/Table.styles';
import { useTableSettings } from '~/hooks/useTableSettings';
import { CSS } from '~/stitches.config';

export type TableProps = {
  css?: CSS;
  columns: any;
  data: any;
  defaultColumn?: any;
  hasFooter?: boolean;
  onUpdateCell?: (index: number, id: string, value: number) => void;
};

export const Table = ({ css, columns, data, defaultColumn, hasFooter, onUpdateCell }: TableProps) => {
  const [tableSettings] = useTableSettings();
  const { footerGroups, getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      onUpdateCell,
    },
    useBlockLayout,
  );

  return (
    <StyledTable {...getTableProps()} css={css}>
      <StyledTableHeader>
        {headerGroups.map((headerGroup) => (
          <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledTableHeaderCell {...column.getHeaderProps()}>{column.render('Header')}</StyledTableHeaderCell>
            ))}
          </StyledTableRow>
        ))}
      </StyledTableHeader>

      <StyledTableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <StyledTableRow
              {...row.getRowProps()}
              css={{
                '&:nth-child(2n)': {
                  backgroundColor: tableSettings.alternativeRowColor ? '$gray100' : 'transparent',

                  '&:hover': {
                    backgroundColor: tableSettings.alternativeRowColor ? '$gray200' : '$gray100',
                  },
                },
              }}
            >
              {row.cells.map((cell) => {
                return <StyledTableCell {...cell.getCellProps()}>{cell.render('Cell')}</StyledTableCell>;
              })}
            </StyledTableRow>
          );
        })}
      </StyledTableBody>
      {hasFooter && (
        <StyledTableFooter>
          {footerGroups.map((group) => (
            <StyledTableRow {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <StyledTableCell {...column.getFooterProps()}>{column.render('Footer')}</StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableFooter>
      )}
    </StyledTable>
  );
};
