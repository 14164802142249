import { ComponentProps } from 'react';

import { styled } from '~/stitches.config';

export const Card = styled('div', {
  backgroundColor: '$background',
  border: '1px solid $colors$gray200',
  borderRadius: 4,
  margin: 0,
  boxSizing: 'border-box',
  overflow: 'hidden',
});

export type CardProps = ComponentProps<typeof Card>;
