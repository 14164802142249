const CREDIT_CALCULATION_PERCENT = 0.045;

export const calculateReturnOnInvestment = (premium: number, age: number) => {
  let calculation: number = premium * (1 + CREDIT_CALCULATION_PERCENT);

  if (age > 1) {
    for (let i = 1; i < age; i++) {
      calculation = (calculation + premium) * (1 + CREDIT_CALCULATION_PERCENT);
    }
  }

  return Math.ceil(calculation);
};

export const calculate = (age: number, outflow: number, percentage: number) => {
  let interestCalculation = outflow * (percentage / 100);
  let balanceCalculation = outflow + interestCalculation;

  if (age > 1) {
    for (let i = 1; i < age; i++) {
      interestCalculation = (balanceCalculation + outflow) * (percentage / 100);
      balanceCalculation = balanceCalculation + outflow + interestCalculation;
    }
  }

  return {
    interest: Math.ceil(interestCalculation),
    balance: Math.ceil(balanceCalculation),
  };
};

export const calculateNoCost = (year: number, premium: number, percentage: number, allPremiums: number[]) => {
  let calculation = allPremiums[0] * (1 + percentage / 100);

  if (year > 1) {
    for (let i = 1; i < year; i++) {
      calculation = (allPremiums[i] + calculation) * (1 + percentage / 100);
    }
  }

  return Math.floor(calculation);
};

export const calculateCost = (cashValue: number, noCost: number) => {
  return noCost - cashValue;
};

export const calculateCostYear = (
  year: number,
  premium: number,
  percentage: number,
  cashValues: number[],
  allPremiums: number[],
) => {
  const noCost = calculateNoCost(1, premium, percentage, allPremiums);
  const cost = calculateCost(cashValues[0], noCost);
  let calculation = cost;

  if (year > 1) {
    for (let i = 1; i < year; i++) {
      const noCost2 = calculateNoCost(i + 1, premium, percentage, allPremiums);

      const cost2 = calculateCost(cashValues[i], noCost2);

      calculation = cost2 - calculation;
    }
  }

  return calculation;
};

export type CalculateLoanParams = {
  outflows: number[];
  percentage: number;
  year: number;
};

export const calculateLoan = ({ outflows, percentage, year }: CalculateLoanParams) => {
  const percent = percentage / 100;
  let interests = outflows[0] * percent;
  let balance = outflows[0] + interests;

  if (year > 1) {
    for (let i = 1; i < year; i++) {
      interests = (balance + outflows[i]) * percent;
      balance = balance + interests + outflows[i];
    }
  }

  return {
    interests: Math.round(interests),
    balance: Math.round(balance),
  };
};

export type CalculateROI = {
  outflows: number[];
  percentage: number;
  year: number;
};

export const calculateROI = ({ outflows, percentage, year }: CalculateROI) => {
  const percent = percentage / 100;

  let returnOnInvestment = outflows[0] * (1 + percent);

  if (year > 1) {
    for (let i = 1; i < year; i++) {
      returnOnInvestment = (returnOnInvestment + outflows[i]) * (1 + percent);
    }
  }

  return Math.round(returnOnInvestment);
};

export type CalculateTotalOOPParams = {
  premiums: number[];
  outflows: number[];
  year: number;
};

export const calculateTotalOOP = ({ outflows, premiums, year }: CalculateTotalOOPParams) => {
  let outOfPocket = premiums[0] - outflows[0];

  if (year > 1) {
    for (let i = 1; i < year; i++) {
      outOfPocket = outOfPocket + (premiums[i] - outflows[i]);
    }
  }

  return outOfPocket;
};
// export const calculateCashValue = ({ outflows, percentage, year }) => {};
