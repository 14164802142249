import * as RadixSwitch from '@radix-ui/react-switch';
import React from 'react';

import { Box, BoxProps } from '~/components/Primitives/Box';

export type SwitchProps = RadixSwitch.SwitchOwnProps & Pick<BoxProps, 'css' | 'id'>;

export const Switch = ({ css, ...restOfProps }: SwitchProps) => {
  return (
    <Box
      as={RadixSwitch.Root}
      css={{
        backgroundColor: '$gray200',
        width: 24,
        height: 12,
        border: 0,
        borderRadius: '24px',
        px: 2,
        cursor: 'pointer',
        ...(css as {}),
      }}
      {...restOfProps}
    >
      <Box
        as={RadixSwitch.Thumb}
        css={{
          display: 'block',
          backgroundColor: '$gray400',
          width: 8,
          height: 8,
          borderRadius: 12,
          transition: 'background-color 100ms, transform 100ms',

          '&[data-state="checked"]': {
            backgroundColor: '$blue800',
            transform: 'translateX(12px)',
          },
        }}
      />
    </Box>
  );
};
