import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { analytics } from '~/analytics/analytics';

import { Stepper } from '~/components/Primitives/Stepper/Stepper';
import { StepperItem } from '~/components/Primitives/Stepper/StepperItem';
import { StepperSpacer } from '~/components/Primitives/Stepper/StepperSpacer';
import { Routes } from '~/constants/routes';

export const CalculatorStepper = () => {
  const router = useRouter();

  return (
    <Stepper css={{ flexGrow: 1, height: '100%' }}>
      <Link href={Routes.calculator.form} passHref>
        <StepperItem
          isActive={router.asPath === Routes.calculator.form}
          onClick={() =>
            analytics.track('Clicked link', {
              parentComponent: 'CalculatorStepper',
              label: 'Form',
            })
          }
          step={1}
          title='Form'
        />
      </Link>
      <StepperSpacer />
      <Link href={Routes.calculator.credit} passHref>
        <StepperItem
          isActive={router.asPath === Routes.calculator.credit}
          onClick={() =>
            analytics.track('Clicked link', {
              parentComponent: 'CalculatorStepper',
              label: 'Credit',
            })
          }
          step={2}
          title='Credit'
        />
      </Link>
      <StepperSpacer />
      <Link href={Routes.calculator.loan} passHref>
        <StepperItem
          isActive={router.asPath === Routes.calculator.loan}
          onClick={() =>
            analytics.track('Clicked link', {
              parentComponent: 'CalculatorStepper',
              label: 'Loan',
            })
          }
          step={3}
          title='Loan'
        />
      </Link>
      <StepperSpacer />
      <Link href={Routes.calculator.results} passHref>
        <StepperItem
          isActive={router.asPath === Routes.calculator.results}
          onClick={() =>
            analytics.track('Clicked link', {
              parentComponent: 'CalculatorStepper',
              label: 'Results',
            })
          }
          step={4}
          title='Results'
        />
      </Link>
    </Stepper>
  );
};
