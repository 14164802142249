import React from 'react';
import { analytics } from '~/analytics/analytics';

import { Box } from '~/components/Primitives/Box';
import { Switch } from '~/components/Primitives/Switch';
import { Text } from '~/components/Primitives/Text';
import { useTableSettings } from '~/hooks/useTableSettings';

export const TableSettings = () => {
  const [tableSettings, setTableSettings] = useTableSettings();

  return (
    <>
      <Box css={{ backgroundColor: '$gray200', height: 1, my: 24 }} />
      <Box css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 24 }}>
        <Text
          as='label'
          css={{ display: 'inline-block', flexGrow: 1, mb: 4, fontWeight: '$medium' }}
          htmlFor='highlightEditableFields'
          size='body-12'
        >
          Highlight editable fields
        </Text>
        <Switch
          checked={tableSettings.highlightEditableFields}
          id='highlightEditableFields'
          onCheckedChange={(value) => {
            setTableSettings({ ...tableSettings, highlightEditableFields: value });

            analytics.track('Clicked button', {
              parentComponent: 'TableSettings',
              label: 'Highlight editable fields',
            });
          }}
        />
      </Box>
      <Box css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 24 }}>
        <Text
          as='label'
          css={{ display: 'inline-block', flexGrow: 1, mb: 4, fontWeight: '$medium' }}
          htmlFor='alternativeRowColor'
          size='body-12'
        >
          Alternative row color
        </Text>
        <Switch
          checked={tableSettings.alternativeRowColor}
          id='alternativeRowColor'
          onCheckedChange={(value) => {
            setTableSettings({ ...tableSettings, alternativeRowColor: value });

            analytics.track('Clicked button', {
              parentComponent: 'TableSettings',
              label: 'Alternative row color',
            });
          }}
        />
      </Box>
      <Box css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Text
          as='label'
          css={{ display: 'inline-block', flexGrow: 1, mb: 4, fontWeight: '$medium' }}
          htmlFor='splitEvery10Rows'
          size='body-12'
        >
          Split every 10 rows
        </Text>
        <Switch
          checked={tableSettings.splitEvery10Rows}
          id='splitEvery10Rows'
          onCheckedChange={(value) => {
            setTableSettings({ ...tableSettings, splitEvery10Rows: value });

            analytics.track('Clicked button', {
              parentComponent: 'TableSettings',
              label: 'Split every 10 rows',
            });
          }}
        />
      </Box>
    </>
  );
};
