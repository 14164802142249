import VisuallyHidden from '@reach/visually-hidden';
import { useTheme } from 'next-themes';
import React from 'react';

import { Box } from '~/components/Primitives/Box';
import { CalculatorIcon, HomeIcon, MoonIcon, SunIcon } from '~/components/Primitives/Icon';
import { IconButton } from '~/components/Primitives/IconButton';
import { Logo } from '~/components/Primitives/Logo';
import { NavigationItem } from '~/components/Navigation/NavigationItem';
import { Routes } from '~/constants/routes';

export type NavigationProps = {
  hasSidebar?: boolean;
};

export const Navigation = () => {
  const { theme, setTheme } = useTheme();
  const isDarkMode = theme === 'dark';

  return (
    <Box
      as='nav'
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '$background',
        py: 24,
        borderRight: '1px solid $colors$gray200',
      }}
    >
      <Box>
        <Logo css={{ mb: 24 }} />
        <NavigationItem href={Routes.home} icon={HomeIcon} label='Home' />
        <NavigationItem href={Routes.calculator.form} icon={CalculatorIcon} label='Calculator' />
      </Box>

      <Box>
        <IconButton onClick={() => (isDarkMode ? setTheme('light') : setTheme('dark'))} size='small'>
          <VisuallyHidden>Switch between dark and light mode</VisuallyHidden>
          <SunIcon css={{ '.light-theme &': { display: 'none' } }} size='small' />
          <MoonIcon css={{ '.dark-theme &': { display: 'none' } }} size='small' />
        </IconButton>
      </Box>
    </Box>
  );
};
