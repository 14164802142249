import React, { ReactNode } from 'react';

import { CalculatorStepper } from '~/components/Calculator/CalculatorStepper';
import { Navigation } from '~/components/Navigation/Navigation';
import { Box } from '~/components/Primitives/Box';
import { Header } from '~/components/Primitives/Header';
import { Toolbar } from '~/components/Toolbar';

export type CalculatorLayoutProps = {
  children: ReactNode;
  layout?: {
    currentStep: string;
    nextStep?: string;
    previousStep?: string;
  };
};

export const CalculatorLayout = ({ children, layout }: CalculatorLayoutProps) => {
  return (
    <Box
      css={{
        display: 'grid',
        gridTemplateColumns: '64px 1fr',
        height: '100%',
      }}
    >
      <Navigation />

      <Box as='main' css={{ overflowY: 'auto' }}>
        <Header>
          <CalculatorStepper />
        </Header>

        {children}
      </Box>
      {layout?.currentStep && (layout?.nextStep || layout.previousStep) && (
        <Toolbar currentStep={layout.currentStep} nextStep={layout.nextStep} previousStep={layout.previousStep} />
      )}
    </Box>
  );
};
