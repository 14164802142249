import React, { forwardRef, MouseEventHandler } from 'react';
import { Box } from '~/components/Primitives/Box';

import { Button, ButtonProps } from '~/components/Primitives/Button';
import { Text } from '~/components/Primitives/Text';

export type StepperItemProps = {
  isActive?: boolean;
  step: number;
  title: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const StepperItem = forwardRef<HTMLAnchorElement, StepperItemProps>(
  ({ isActive, step = 1, title, ...restOfProps }: StepperItemProps, ref) => {
    return (
      <Button
        appearance='secondary'
        as='a'
        css={{
          height: 'auto',
          border: 0,
          py: 8,
          mx: 12,

          '&:hover': {
            border: 0,
          },

          '&:last-child': {
            mr: 0,
          },
        }}
        ref={ref}
        {...restOfProps}
      >
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: isActive ? '$blue700' : '$gray100',
            width: 24,
            height: 24,
            borderRadius: 12,
            color: isActive ? 'white' : undefined,
            mr: 12,
          }}
        >
          <Text css={{ color: 'inherit' }} size='heading-12-uppercase'>
            {step}
          </Text>
        </Box>
        <Text size='body-14'>{title}</Text>
      </Button>
    );
  },
);
