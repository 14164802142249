import { createLocalStorageStateHook } from 'use-local-storage-state';

export type FormattedData = {
  year: number;
  age: number;
  premium: number;
  cashValue: number;
  deathBenefits: number;
};

export type InputData = {
  ameritas: string;
  loanPercentage: string;
};

export type CalculatorData = {
  credit: {
    input: InputData;
    formatted?: FormattedData[];
  };
  form: {
    input: InputData;
    formatted?: FormattedData[];
  };
  loan: {
    input: InputData;
    formatted?: FormattedData[];
  };
};

export const INITIAL_VALUES: CalculatorData = {
  credit: {
    input: {
      ameritas: '',
      loanPercentage: '3.5',
    },
    formatted: [],
  },
  form: {
    input: {
      ameritas: '',
      loanPercentage: '3.5',
    },
    formatted: [],
  },
  loan: {
    input: {
      ameritas: '',
      loanPercentage: '3.5',
    },
    formatted: [],
  },
};

export const useCalculatorValues = createLocalStorageStateHook<CalculatorData>('calculator/values', INITIAL_VALUES);
