import React from 'react';

import { Box, BoxProps } from '~/components/Primitives/Box';

export type StepperProps = BoxProps;

export const Stepper = ({ children, css }: StepperProps) => {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '$background',
        mx: -12,
        overflow: 'hidden',
        ...(css as any),
      }}
    >
      {children}
    </Box>
  );
};
