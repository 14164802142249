import { ComponentProps } from 'react';

import { styled } from '~/stitches.config';

export const Button = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  fontFamily: '$inter',
  letterSpacing: -0.2,
  textAlign: 'center',
  textDecoration: 'none',
  cursor: 'pointer',

  '&:focus': {
    outline: 'none',
  },

  '&:focus-visible': {
    boxShadow: '0 0 0 2px $colors$blue800',
  },

  variants: {
    appearance: {
      primary: {
        backgroundColor: '$blue800',
        border: 'none',
        color: '$white',
        fontWeight: '$semibold',

        '&:hover': {
          backgroundColor: '$blue700',
        },
      },
      secondary: {
        backgroundColor: 'transparent',
        border: '1px solid $colors$gray200',
        color: '$gray800',
        fontWeight: '$regular',

        '&:hover': {
          backgroundColor: '$gray100',
        },
      },
    },
    size: {
      large: {
        height: 48,
        paddingLeft: 24,
        paddingRight: 24,
        fontSize: 18,
      },
      medium: {
        height: 40,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 16,
      },
      small: {
        height: 32,
        paddingLeft: 16,
        paddingRight: 16,
        fontSize: 12,
      },
    },
  },

  defaultVariants: {
    appearance: 'primary',
    size: 'small',
  },
});

export type ButtonProps = ComponentProps<typeof Button>;
